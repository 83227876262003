
import { Container } from "./styles";
import Septian from "../../assets/iann.png";
import wordpress from "../../assets/wordpress.svg";
import figma from "../../assets/figma.gif";
import htmlIcon from "../../assets/html-icon.svg";
import cssIcon from "../../assets/css-icon.svg";
import jsIcon from "../../assets/tail.gif";
import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import vueIcon from "../../assets/vue-icon.svg";
import laravel from "../../assets/laravel.svg";
import gpt from "../../assets/gpt.gif";
import cs from "../../assets/cs.png";
import ps from "../../assets/ps.png";
import boostrapIcon from "../../assets/bootstrap-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" style={{ marginTop: "2rem", marginBottom: "2rem" }} delay={0.1 * 1000}>
          <p style={{fontFamily: "Italianno ,cursive",fontSize:"2rem" ,fontWeight: "400",fontStyle: "normal"}}>
          Fueled by passion, driven by code.
          </p>
        </ScrollAnimation>
        
       <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
       <p>
         Hello Everyone who opening this link! I'm Septian, a  
         <a 
           href="https://en.wikipedia.org/wiki/Front-end_web_development" 
           target="_blank" 
           rel="noopener noreferrer" 
           style={{ color: "#FD3A4A", fontWeight:"bold", textDecoration: "none", cursor: "pointer" }}
         >  
            <span> </span>junior Front-End <span> </span>
         </a> 
         Web Developer who specializes in creating tailored digital experiences and captivating visuals. With a strong foundation in HTML, CSS, JavaScript, React, and design tools like Adobe Photoshop and Illustrator.
       </p>
     </ScrollAnimation>
     <br />
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
          I can bring your ideas to life both functionally and aesthetically. Additionally, my expertise in content management systems like WordPress allows me to deliver solutions that are easy for you to maintain and update, ensuring your brand stands out in the ever-evolving digital landscape.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Here are my main skills:</h3>
        </ScrollAnimation>
        <div className="hard-skills">
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.10 * 1000}>
              <a href="https://en.wikipedia.org/wiki/WordPress" target="_blank" rel="noopener noreferrer">
                <img src={wordpress} alt="Wordpress" />
              </a>
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.30 * 1000}>
              <a href="https://en.wikipedia.org/wiki/React_(JavaScript_library)" target="_blank" rel="noopener noreferrer">
                <img src={reactIcon} alt="React" />
              </a>
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.50 * 1000}>
              <a href="https://en.wikipedia.org/wiki/Figma_(software)" target="_blank" rel="noopener noreferrer">
                <img src={figma} alt="figma" />
              </a>
            </ScrollAnimation>
          </div>
          
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.70 * 1000}>
              <a href="https://en.wikipedia.org/wiki/HTML" target="_blank" rel="noopener noreferrer">
                <img src={htmlIcon} alt="Html" />
              </a>
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.80 * 1000}>
              <a href="https://en.wikipedia.org/wiki/Laravel" target="_blank" rel="noopener noreferrer">
                <img src={laravel} alt="Laravel" />
              </a>
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.90 * 1000}>
              <a href="https://en.wikipedia.org/wiki/GPT" target="_blank" rel="noopener noreferrer">
                <img src={gpt} alt="GPT" />
              </a>
            </ScrollAnimation>
          </div>
         
         
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
              <a href="https://en.wikipedia.org/wiki/C-sharp" target="_blank" rel="noopener noreferrer">
                <img src={cs} alt="C Sharp" />
              </a>
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={1.10 * 1000}>
              <a href="https://en.wikipedia.org/wiki/photoshop" target="_blank" rel="noopener noreferrer">
                <img src={ps} alt="Adobe Photoshop" />
              </a>
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={1.20 * 1000}>
              <a href="https://en.wikipedia.org/wiki/tailwind" target="_blank" rel="noopener noreferrer">
                <img src={jsIcon} alt="Tailwind CSS" />
              </a>
            </ScrollAnimation>
          </div>
          <br />
          
        </div>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={Septian} alt="Septian Hidayat Hidayat" />
        </ScrollAnimation>
      </div>
      <br />
     
    </Container>
  )
}