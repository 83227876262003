import { Container, ContainerSucces } from './styles'
import { useForm, ValidationError } from '@formspree/react'
import { toast, ToastContainer } from 'react-toastify'
import { useEffect, useState } from 'react'
import validator from 'validator'
import ScrollAnimation from 'react-animate-on-scroll'
import { Turnstile } from '@marsidev/react-turnstile'

export function Form() {
  const [state, handleSubmit] = useForm('xknkpqry')
  const [validEmail, setValidEmail] = useState(false)
  const [isHuman, setIsHuman] = useState(false)
  const [message, setMessage] = useState('')

  function verifyEmail(email: string) {
    if (validator.isEmail(email)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }
  const showAlert = () => {
    alert("Noted!   @septian");
  };
  useEffect(() => {
    if (state.succeeded) {
      toast.success('Email successfully sent!', {
        position: toast.POSITION.BOTTOM_LEFT,
        pauseOnFocusLoss: false,
        closeOnClick: true,
        hideProgressBar: false,
        toastId: 'succeeded',
      })
    }
  })

  if (state.succeeded) {
    return (
      <ContainerSucces>
        <h3>Thanks for getting in touch!</h3>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        >
          Back to the top
        </button>
        <ToastContainer />
      </ContainerSucces>
    )
  }

  return (
    <Container>
      <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
        <h2>Get in touch using the form</h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn="slideInUp">
        <form onSubmit={handleSubmit}>
          <input
            placeholder="Email"
            id="email"
            type="email"
            name="email"
            onChange={(e) => {
              verifyEmail(e.target.value)
            }}
           
            required
          />

          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <textarea
            required
            placeholder="Send a message to get started."
            id="message"
            name="message"
            
            onChange={(e) => {
              setMessage(e.target.value)
            }}
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          
          {/* Mengganti ReCAPTCHA dengan Cloudflare Turnstile */}
          <Turnstile
            siteKey="site_key_cloudflare" // Ganti dengan site key Turnstile kamu
            onSuccess={(token) => setIsHuman(true)}
          />

          <button
            type="submit"
           // disabled={state.submitting || !validEmail || !message || !isHuman}
            onClick={showAlert} 
          >
            Submit
          </button>
        </form>
        <ToastContainer />
      </ScrollAnimation>
    </Container>
  )
}
