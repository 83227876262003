import styled, { keyframes } from "styled-components";

// Keyframe animations
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.section<{ showMore: boolean }>`
  padding: 4rem 2rem;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  .section-title {
    font-size: 3.5rem;
    color: white;
    margin-bottom: 2rem;
  }

  .toggle-button {
    position: absolute;
    top: 1rem;
    right: 2rem;
    background: transparent;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s;

    &:hover {
     
      color: #FD3A4A;
    }
  }

  .certificate-gallery {
    display: grid;
    grid-template-columns: ${({ showMore }) => (showMore ? "repeat(3, 1fr)" : "repeat(3, 1fr)")};
    gap: 1.5rem;

    @media (max-width: 1024px) {
      grid-template-columns: ${({ showMore }) => (showMore ? "repeat(2, 1fr)" : "1fr")};
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      max-height: ${({ showMore }) => (showMore ? "400px" : "auto")};
      overflow-y: ${({ showMore }) => (showMore ? "auto" : "hidden")};
      padding-right: ${({ showMore }) => (showMore ? "1rem" : "0")};
    }
  }

  .certificate-item {
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: ${fadeIn} 0.8s ease forwards;

    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      max-height: 200px;
      object-fit: cover;
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
`;
