import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg"
import { Form } from "../Form/Form";
import ScrollAnimation from "react-animate-on-scroll";

export function Contact(){

  return(
    <Container id="contact">
      <header> <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
        <h2>Contact</h2>
        <p>Do You Want to know me? </p>
        <p>Contact me now</p>
        </ScrollAnimation>
      </header>
      <ScrollAnimation animateIn="zoomInDown" duration={1.5}>
      <div className="contacts">
        <div>
        <a href="mailto:me.septianhidayat@gmail.com"><img src={emailIcon} alt="Email" /></a> 
          <a  href="mailto:me.septianhidayat@gmail.com">me.septianhidayat@gmail.com</a>
        </div>
        <div>
        <a href="tel:+6283149619435"><img src={phoneIcon} alt="Phone No" /></a>
          <a href="tel:+6283149619435">(+62) 83149619435</a>
        </div>  
      </div>
      </ScrollAnimation>
     
      <Form></Form>
    </Container>
  )
}