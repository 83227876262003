import { Container } from './styles';
import { NavHashLink, HashLink } from 'react-router-hash-link';
import { useState } from 'react';
import s from '../../assets/s.gif';
import { GlobalStyle } from '../../styles/global';
import Resume from '../../assets/RESUME_SEPTIAN.pdf';

export function Header() {
  const [isActive, setActive] = useState(false);

  function toggleTheme() {
    let html = document.getElementsByTagName('html')[0];
    html.classList.toggle('light');
  }

  function closeMenu() {
    setActive(false);
  }

  const showAlert = () => {
    alert("Coming Soon!   @septian");
  };

  return (
    <Container className="header-fixed">
      <HashLink smooth to="#home" className="logo">
        <span style={{ fontFamily: 'Amarillo, sans-serif' }}>
          <img src={s} alt="s" width="40px" />
        </span>
      </HashLink>
      <input
        onChange={toggleTheme}
        className="container_toggle"
        type="checkbox"
        id="switch"
        name="mode"
      />
      <label htmlFor="switch">Toggle</label>
      <nav className={isActive ? 'active' : ''}>
        <NavHashLink smooth to="/" onClick={closeMenu}>
          Home
        </NavHashLink>
        <NavHashLink smooth to="/#about" onClick={closeMenu}>
          About me
        </NavHashLink>
        <NavHashLink smooth to="/#project" onClick={closeMenu}>
          Project
        </NavHashLink>
        <NavHashLink smooth to="/#contact" onClick={closeMenu}>
          Contact
        </NavHashLink>
        <NavHashLink smooth to="blogs" onClick={closeMenu}>
          Blog
        </NavHashLink>
        <a href={Resume} download className="button">
          Resume
        </a>
      </nav>
      <div
        aria-expanded={isActive ? 'true' : 'false'}
        aria-haspopup="true"
        aria-label={isActive ? 'Fechar menu' : 'Abrir menu'}
        className={isActive ? 'menu active' : 'menu'}
        onClick={() => {
          setActive(!isActive);
        }}
      ></div>
    </Container>
  );
}
