// src/components/Blog/blogData.ts
import coding from "../../assets/coding.jpg"
import ai from "../../assets/ai.jpg"
import desain from "../../assets/desain.jpg"
import uiux from "../../assets/uiux.jpg"
import responsif from "../../assets/responsif.png"

export const blogsData = [
  {
    id: 1,
    title: 'NGODING, apa sih?',
    author: 'Septian Hidayat',
    date: '2 Agustus, 2024',
    image: coding,
    credit: 'pexels.com',
    content: [
      `"Ngoding" atau Coding adalah membuat suatu kode program supaya komputer bisa menjalankan apa yang saya inginkan. Jadi, bayangkan kamu sedang berbicara dengan komputer menggunakan bahasa yang dia mengerti, tetapi bukan bahasa manusia biasa, melainkan bahasa pemrograman seperti Python, JavaScript, atau C++.`,
      `Waktu saya masih bersekolah di SMK dengan jurusan RPL, biasanya akan banyak menemui hal-hal seperti:`,
      `\n1. **Dasar-dasar Pemrograman**\n \nDi sini kamu diajarkan bagaimana cara menulis kode yang benar, seperti cara menggunakan if-else, loops (perulangan), atau membuat variabel. Bahasa pemrograman pertama yang diajarkan biasanya Pascal atau Python. Intinya, kamu akan belajar logika dasar untuk memahami bagaimana memberi instruksi kepada komputer.`,
      `\n2. **Website Development**\n \nBiasanya di SMK juga ada materi membuat website. Mulai dari HTML untuk membuat struktur, CSS untuk mengatur tampilannya agar keren, dan JavaScript untuk membuat website menjadi dinamis (bisa interaktif). Selain itu, kamu juga akan belajar tentang framework seperti React atau Angular yang mempermudah proses pengembangan website.`,
      `\n3. **Database**\n \nIni adalah bagian di mana kamu akan belajar cara menyimpan data, misalnya menggunakan MySQL atau PostgreSQL. Jadi, kamu tidak hanya membuat aplikasi yang keren, tetapi juga bisa menyimpan data di belakangnya. Pemahaman tentang database ini sangat penting untuk mengelola data pengguna dan informasi penting lainnya.`,
      `\n4. **Proyek Aplikasi (UJIKOM)**\n \nBiasanya di akhir semester, kamu akan disuruh membuat proyek besar seperti aplikasi sederhana atau website. Ini untuk menunjukkan kemampuan yang telah dipelajari selama sekolah. Tantangannya bisa seru, karena kamu harus menggabungkan semuanya dari desain hingga coding, bahkan ada integrasi dengan API🔥. Proyek ini juga membantu kamu memahami workflow pengembangan software dari awal hingga akhir.`,
      `\n5. **Debugging dan Pemecahan Masalah**\n \nSelain belajar menulis kode, debugging adalah keterampilan penting yang harus kamu kuasai. Debugging adalah proses menemukan dan memperbaiki kesalahan dalam kode. Ini melatih kamu untuk berpikir logis dan analitis, serta meningkatkan ketelitian dalam menulis kode.`,
      `\n6. **Pengenalan ke Algoritma dan Struktur Data**\n \nUntuk menjadi seorang programmer yang handal, pemahaman tentang algoritma dan struktur data sangat penting. Ini membantu kamu untuk menyelesaikan masalah kompleks dan mengoptimalkan kinerja aplikasi. Belajar tentang algoritma sorting, searching, dan berbagai struktur data seperti linked list, stack, dan queue akan sangat berguna.`,
      `\n7. **Pemrograman Berorientasi Objek (PBO)**\n \nOOP adalah paradigma pemrograman yang membantu dalam mengorganisir kode dan mengurangi kompleksitas. Dengan memahami konsep seperti kelas, objek, inheritance, dan polymorphism, kamu bisa membuat kode yang lebih modular dan mudah dipelihara.`,
      `\n8. **Pengenalan ke Framework dan Library**\n \nSetelah menguasai dasar-dasar pemrograman, mempelajari framework dan library seperti React, Angular, atau Vue.js akan mempercepat proses pengembangan aplikasi. Framework ini menyediakan struktur dan komponen yang siap pakai, sehingga kamu bisa fokus pada pengembangan fitur-fitur unik aplikasi.`,
  
    ],
    content2: [
      `\nJangan kaget kalau ngoding itu penuh dengan error... malah, programmer sering banget hidup dari debugging alias mencari dan memperbaiki kesalahan di kode. Kadang-kadang membuat frustrasi, tapi justru itu bagian serunya wkwkwk. Trus, ngoding bukan tentang seberapa cepat kamu mengetik, tidak seperti di film-film hacker yang tangannya terbang di keyboard. Yang penting adalah logika dan bagaimana menyelesaikan masalah dengan efisien. Selain itu, setiap bahasa pemrograman juga punya gayanya sendiri, jadi jika kamu bisa beberapa bahasa, terasa seperti menjadi multilingual versi programmer.`,
      `\n**Pentingnya Dokumentasi**\n \nMembuat dokumentasi yang baik sangat penting dalam pengembangan software. Dokumentasi membantu kamu dan timmu untuk memahami kode yang telah dibuat, mempermudah proses perbaikan, dan menyederhanakan pengembangan fitur baru. Ini juga penting ketika kamu bekerja dalam tim besar atau ketika harus mentransfer proyek kepada pengembang lain.`,
      `\n**Mengikuti Best Practices**\n \nDalam pemrograman, mengikuti best practices seperti menulis kode yang bersih dan terstruktur, menggunakan naming conventions yang konsisten, dan mengoptimalkan performa sangat penting. Best practices ini membantu membuat kode lebih mudah dibaca dan dipelihara, serta mengurangi kemungkinan bug.`,
      `\n**Menggunakan Tools dan IDE yang Tepat**\n \nIntegrated Development Environment (IDE) seperti Visual Studio Code, IntelliJ IDEA, atau PyCharm sangat membantu dalam proses pengembangan. IDE menyediakan fitur seperti syntax highlighting, auto-completion, dan debugging tools yang mempermudah kamu dalam menulis dan memperbaiki kode.`,
      `\n**Belajar dari Proyek Open Source (COPAS PROJEK :D)**\n \nBerpartisipasi dalam proyek open source adalah cara yang bagus untuk mengasah keterampilan coding, berkolaborasi dengan pengembang lain, dan menambah portofolio. Ini juga memberikan pengalaman nyata dalam bekerja pada proyek skala besar dan mengatasi tantangan dunia nyata.`,
      `\n**Kembangkan Soft Skills!**\n \nSelain keterampilan teknis, soft skills seperti komunikasi efektif, kerja tim, dan manajemen waktu juga sangat penting. Soft skills ini membantu kamu untuk bekerja lebih baik dalam tim, mengelola proyek dengan efisien, dan beradaptasi dengan perubahan dalam lingkungan kerja.`,
      `\n**Menetapkan Tujuan dan Rencana Karir**\n \nMenetapkan tujuan jangka pendek dan jangka panjang dalam karirmu sebagai programmer sangat penting. Membuat rencana karir membantu kamu untuk memfokuskan usaha dan belajar sesuai dengan tujuan yang diinginkan. Ini juga membantu kamu untuk mengukur kemajuan dan menyesuaikan strategi jika diperlukan.`,
      `\n**HARUS jaga Kesabaran dan Ketekunan**\n \nBelajar pemrograman memerlukan kesabaran dan ketekunan. Tidak ada yang instan dalam menguasai keterampilan ini. Teruslah belajar, praktik secara konsisten, dan jangan menyerah saat menghadapi tantangan. Dengan waktu dan usaha, kamu akan menjadi programmer yang handal dan mampu menghadapi berbagai masalah dengan percaya diri.`
    ]
  },


  {
    id: 2,
    title: 'Rekomendasi AI untuk membantu tugas kuliah',
    author: 'Septian Hidayat',
    date: '12 Agustus, 2024',
    image: ai,
    credit: 'pexels.com',
    content: [
      `Di era digital saat ini, teknologi telah menjadi bagian tak terpisahkan dari kehidupan saya, termasuk dalam dunia pendidikan. Banyak mahasiswa yang mencari cara untuk mempermudah proses belajar dan meningkatkan kualitas tugas kuliah mereka. Salah satu solusi terbaik yang muncul adalah penggunaan kecerdasan buatan (AI). Alat-alat AI ini dapat memberikan dukungan yang signifikan, mulai dari membantu menemukan informasi hingga memperbaiki tata bahasa dan presentasi. Di blog ini, saya akan membahas beberapa rekomendasi alat AI yang dapat kamu gunakan untuk membantu tugas kuliah (**bukan buat ujian ya :v**).`
    ],
    content2: [
      `\n1. **ChatGPT**\n \nSiapa sih yang gak tau ChatGPT? AI ini sangat membantu menjawab pertanyaan, menjelaskan konsep sulit, dan bahkan memberikan ide untuk proyek. Tinggal ketik pertanyaanmu, dan lihat bagaimana AI ini bisa menjadi sumber informasi yang berharga. ChatGPT juga bisa membantu membuat outline tugas, mengoreksi kesalahan tata bahasa, dan mengusulkan topik-topik menarik untuk penelitianmu.`,
      `\n2. **Grammarly**\n\nGrammarly adalah sahabat yang akan memeriksa ejaan, tata bahasa, dan bahkan gaya penulisanmu. Dengan fitur saran yang cerdas, kamu bisa membuat tugasmu terlihat lebih profesional. Grammarly juga menyediakan fitur untuk mendeteksi plagiarisme, memastikan bahwa tulisanmu orisinal dan bebas dari penyalinan tanpa izin.`,
      `\n3. **Mendeley**\n\nButuh referensi untuk makalahmu? Mendeley bukan hanya alat manajemen referensi, tetapi juga platform kolaborasi. Dengan Mendeley, kamu bisa menyimpan, mengorganisir, dan membuat sitasi dengan mudah. Plus, kamu bisa terhubung dengan teman-teman sekelas untuk berbagi sumber. Fitur discovery Mendeley membantu kamu menemukan artikel-artikel relevan yang bisa digunakan dalam penelitianmu.`,
      `\n4. **Canva**\n\nKalau kamu harus membuat presentasi atau poster untuk tugas, Canva bisa jadi pilihan tepat. Dengan berbagai template yang menarik dan alat desain yang mudah digunakan, kamu bisa membuat visual yang eye-catching dalam waktu singkat. Canva juga menawarkan fitur kolaborasi, memungkinkan kamu untuk bekerja sama dengan rekan dalam mendesain materi presentasimu.`,
      `\n5. **Otter.ai**\n\nUntuk yang suka merekam kuliah atau diskusi, Otter.ai adalah asisten transkripsi yang sangat membantu. Cukup rekam, dan AI ini akan mengubah suara menjadi teks. Kamu bisa fokus menyimak tanpa khawatir kehilangan informasi penting. Otter.ai juga menyediakan fitur tagging dan highlight, memudahkan kamu untuk menandai bagian-bagian penting dalam transkripmu.`,
      `\n6. **Khan Academy**\n\nKhan Academy menawarkan berbagai video pembelajaran interaktif di banyak bidang. Dari matematika hingga ilmu komputer, kamu bisa menemukan penjelasan yang jelas dan sederhana. Siapa bilang belajar itu membosankan? Dengan video interaktif ini, belajar jadi lebih menyenangkan. Selain itu, Khan Academy juga menyediakan latihan soal yang membantu kamu mengukur pemahamanmu terhadap materi yang telah dipelajari.`,
      `\n7. **DeepL Translator**\n\nKalau kamu butuh menerjemahkan dokumen atau teks dari bahasa asing, DeepL adalah pilihan yang cerdas. Dengan akurasi tinggi dan kemampuan menangkap nuansa bahasa, kamu bisa mendapatkan terjemahan yang alami dan sesuai konteks. Sekali lagi, siapa yang mau pekerjaan/tugasnya terlihat asal-asalan XD? DeepL membantu kamu untuk menyampaikan ide dengan jelas dan tepat dalam bahasa yang berbeda.`,
      `\nKeuntungan Menggunakan AI untuk Tugas Kuliah\n
      Dengan menggunakan alat-alat AI, kamu tidak hanya menghemat waktu, tetapi juga meningkatkan kualitas tugasmu. AI membantu kamu untuk mengoptimalkan proses belajar, mempercepat penyelesaian tugas, dan menjamin bahwa hasil kerja kamu lebih profesional. Selain itu, AI juga membantu kamu untuk lebih fokus pada aspek kreatif dari tugasmu, sementara aspek teknis ditangani oleh teknologi.`
    ]
  },

  {
    id: 3,
    title: 'Seputar Desain Grafis',
    author: 'Septian Hidayat',
    date: '28 Agustus, 2024',
    image: desain, 
    credit: 'pexels.com',
    content: [
      `Desain grafis lebih dari sekadar gambar yang indah; ini adalah cara saya berkomunikasi melalui visual. Di era digital sekarang, desain grafis menjadi semakin penting, baik untuk keperluan pribadi maupun profesional. Dari membuat poster yang menarik hingga mendesain logo yang mudah diingat, setiap elemen desain memiliki perannya masing-masing. Dalam blog kali ini, saya akan membahas lebih dalam tentang desain grafis, pentingnya, alat-alat yang bisa kamu gunakan, tips untuk meningkatkan keterampilan, hingga tren terbaru di dunia desain.`
    ],
    content2: [
      `\nKenapa Desain Grafis Itu Penting?\n
      Desain yang baik membantu menyampaikan pesan dengan cara yang lebih menarik dan mudah dipahami. Bayangkan kamu melihat dua poster: satu yang berwarna-warni dan penuh ilustrasi, dan satu lagi yang hanya teks biasa. Mana yang lebih menarik perhatian? Tentu yang pertama! Desain yang baik bisa menarik perhatian orang dan membuat mereka lebih tertarik untuk mengetahui lebih lanjut.`,
      `\nDesain grafis juga memainkan peran penting dalam branding. Logo dan identitas visual yang kuat dapat membuat merekmu lebih mudah diingat. Ketika kamu melihat logo tertentu, kamu langsung teringat pada perusahaan atau produk tersebut. Inilah kekuatan desain grafis: mampu menciptakan kesan pertama yang tak terlupakan. Selain itu, dalam dunia digital, desain grafis yang efektif dapat meningkatkan keterlibatan pengguna. Situs web atau aplikasi yang memiliki desain yang menarik dan intuitif dapat membuat pengguna betah berlama-lama dan kembali lagi.`,
      
      `\nAlat dan Software untuk Desain Grafis\n
      Buat kamu yang baru mulai, ada banyak alat dan software yang bisa membantu, seperti:
      - Adobe Photoshop: Ideal untuk editing foto dan manipulasi gambar. Photoshop memungkinkan kamu untuk mengedit gambar secara mendalam, menambahkan efek khusus, dan melakukan retouching untuk menghasilkan gambar yang sempurna.
      - Adobe Illustrator: Bagus untuk membuat ilustrasi dan desain berbasis vektor. Dengan Illustrator, kamu bisa membuat logo dan grafis yang bisa diperbesar tanpa kehilangan kualitas, menjadikannya pilihan tepat untuk desain yang membutuhkan ketajaman tinggi.
      - Canva: Platform online yang mudah digunakan untuk desain cepat dan kreatif, cocok untuk pemula. Canva menawarkan berbagai template untuk berbagai keperluan, mulai dari poster hingga media sosial, sehingga kamu bisa langsung mulai mendesain tanpa harus memahami semua aspek teknis desain.
      - Figma: Cocok untuk desain UI/UX dan kolaborasi dalam proyek. Figma memungkinkan beberapa pengguna untuk bekerja pada proyek yang sama secara real-time, membuatnya ideal untuk tim yang bekerja jarak jauh.
      - Procreate: Aplikasi desain berbasis iPad yang sangat populer di kalangan ilustrator. Procreate menawarkan berbagai alat menggambar dan painting yang memberikan pengalaman menggambar yang intuitif.`,
      
      `\nTips untuk Meningkatkan Keterampilan Desainmu\n
      1. Berlatih Secara Rutin: Seperti keterampilan lainnya, latihan adalah kunci. Cobalah mendesain sesuatu setiap hari, bahkan jika itu hanya untuk bersenang-senang. Dengan berlatih secara konsisten, kamu akan mulai memahami elemen desain dengan lebih baik dan mengembangkan gaya pribadi.
      2. Belajar dari Desainer Lain: Ikuti desainer favoritmu di media sosial dan pelajari teknik mereka. Banyak desainer berbagi tips dan tutorial yang bisa kamu tiru. Jangan ragu untuk mencari inspirasi dari berbagai sumber—setiap desainer punya cara unik dalam menyampaikan pesan melalui visual.
      3. Ikut Kursus Online: Platform seperti Skillshare atau Udemy menawarkan kursus desain grafis yang dapat membantumu memahami dasar-dasar hingga teknik yang lebih kompleks. Belajar dari instruktur yang berpengalaman bisa memberikan wawasan baru dan meningkatkan keterampilanmu dengan cepat.
      4. Jangan Takut Bereksperimen: Cobalah berbagai gaya dan teknik. Desain itu tentang eksplorasi, jadi jangan ragu untuk berinovasi dan menemukan gayamu sendiri! Jika kamu merasa stuck, coba deh untuk mengambil inspirasi dari tren desain terbaru atau bahkan gaya desain yang tidak biasa.
      5. Minta Umpan Balik: Jangan ragu untuk meminta pendapat orang lain tentang karyamu. Umpan balik yang konstruktif bisa membantu kamu melihat sesuatu dari sudut pandang yang berbeda dan meningkatkan kualitas desainmu.`,
      
      `\nTren Desain Grafis Terkini\n
      Desain grafis terus berkembang, dan penting untuk tetap up-to-date dengan tren terbaru. Beberapa tren desain grafis yang sedang populer saat ini antara lain:
      - Minimalisme: Desain sederhana dengan ruang putih yang banyak, memfokuskan perhatian pada elemen yang paling penting.
      - Tipografi yang Berani: Penggunaan font yang mencolok dan unik bisa membuat pesan lebih menarik dan berkesan.
      - Warna Gradasi: Penggunaan warna gradasi yang halus memberikan kedalaman dan dimensi pada desain, membuatnya lebih hidup.
      - Ilustrasi Kustom: Banyak merek beralih ke ilustrasi kustom untuk memberikan identitas unik dan lebih personal pada produk mereka.`,
      
      `\nKesimpulan\n
      Desain grafis adalah dunia yang penuh kreativitas dan peluang. Dengan alat yang tepat dan semangat belajar, kamu bisa membuat karya-karya yang tidak hanya menarik secara visual, tetapi juga bermakna. Jadi, siap untuk mengeksplorasi dan mengekspresikan dirimu melalui desain? Mari mulai perjalanan kreatifmu dan lihat sejauh mana imajinasimu bisa membawamu! Selamat berkarya, dan jangan lupa untuk bersenang-senang dalam prosesnya! 🎨✨`
    ]
  },
  {
    id: 4,
    title: 'UI/UX: Pelarian bagi yang Gak Bisa Ngoding?',
    author: 'Septian Hidayat',
    date: '3 September, 2024',
    image: uiux,
    credit: 'pexels.com',
    content: [
      `**Ada alternatif yang menarik dan penuh peluang: desain UI (User Interface) dan UX (User Experience).** Dalam dunia yang semakin terhubung, **desain UI/UX menjadi semakin penting**, dan ini bisa jadi **pelarian yang sempurna bagi kamu yang ingin terjun ke dunia teknologi tanpa harus terjebak dalam kode-kode rumit**.`
    ],
    content2: [
      `**Pengenalan ke UI dan UX**\n
      **User Interface (UI)** adalah **wajah dari produk digital** yang kita gunakan. Setiap tombol, ikon, dan layout yang kita lihat adalah hasil dari **desain UI yang cermat**. **Desainer UI bertanggung jawab untuk menciptakan elemen visual yang menarik dan mudah digunakan**. Bayangkan kamu membuka sebuah aplikasi dengan **tampilan yang bersih dan intuitif**. Nah, inilah hasil kerja keras seorang desainer UI. Jadi, kalau kamu punya **bakat di bidang seni atau kreativitas**, ini bisa jadi jalan yang pas untuk kamu.`,
      `\n**User Experience (UX)**\n
      **User Experience (UX)** berfokus pada **bagaimana pengguna berinteraksi dengan produk dari awal hingga akhir**. Ini tentang **memahami kebutuhan pengguna**, menciptakan **alur yang mulus**, dan memberikan **pengalaman yang menyenangkan**. Dengan melakukan **riset pengguna**, desainer UX bisa mengetahui apa yang diinginkan oleh pengguna dan merancang produk yang **sesuai dengan harapan mereka**. Jadi, meskipun kamu tidak bisa ngoding, dengan **keterampilan dalam riset dan analisis**, kamu bisa **berkontribusi besar dalam dunia desain**.`,
      `\n**Studi Kasus: Platform Penyewaan Akomodasi**\n
      Misalnya, salah satu **platform penyewaan akomodasi terbesar di dunia** menginvestasikan banyak waktu dan sumber daya untuk **memahami pengalaman pengguna**. Dengan melakukan **wawancara dan survei**, tim desain tersebut bisa mengetahui bagaimana pengguna mencari tempat menginap. Dari hasil riset, mereka **mengubah layout website mereka agar lebih intuitif dan menarik**. **Hasilnya? Tingkat konversi mereka meningkat drastis**, dan pengguna merasa **lebih puas saat menggunakan platform tersebut**. Ini menunjukkan bahwa dengan fokus pada UX, sebuah produk bisa mengalami **pertumbuhan yang signifikan**.`,
      `\n**Studi Kasus: Layanan Streaming Musik**\n
      Studi kasus lainnya datang dari sebuah **layanan streaming musik** yang menyadari bahwa banyak pengguna yang merasa kesulitan menemukan musik baru yang sesuai dengan selera mereka. Tim UX layanan ini melakukan **analisis mendalam** dan menghadirkan **fitur rekomendasi musik personal** yang menggunakan algoritma. **Desain UI yang bersih dan responsif** membuat pengguna **mudah untuk menjelajahi dan menemukan musik baru**. **Hasilnya**, layanan ini tidak hanya **meningkatkan pengalaman pengguna**, tetapi juga berhasil **meningkatkan retensi pengguna dan loyalitas pelanggan**.`,
      `\n**Perkembangan Tren dan Teknologi Baru dalam UI/UX**\n
      Yang menarik, **UI/UX saat ini semakin berkembang seiring dengan tren dan teknologi baru**. Banyak perusahaan yang **mencari desainer UI/UX** untuk membantu mereka **menciptakan produk yang tidak hanya menarik secara visual**, tetapi juga **fungsional dan mudah digunakan**. Ini berarti bahwa **ada banyak peluang kerja di bidang ini**, dan kamu bisa menjadi **bagian dari industri yang berkembang pesat tanpa harus menjadi programmer**.`,
      `\n**Kolaborasi dengan Tim Pengembang**\n
      Namun, **desain UI/UX bukan hanya tentang estetika**. **Keberhasilan sebuah produk** ditentukan oleh **seberapa baik produk tersebut memenuhi kebutuhan pengguna**. **Proses pengujian dan umpan balik dari pengguna** sangat penting untuk memastikan bahwa **desain yang dibuat efektif**. **Desainer UI/UX bekerja sama dengan tim pengembang** untuk menciptakan produk yang **memuaskan dan bermanfaat**.`,
      `\n**Persiapan untuk Karir di Bidang UI/UX**\n
      Jadi, jika kamu merasa ngoding bukan untukmu, **UI/UX bisa jadi pilihan yang menarik**. Ini adalah **kesempatan untuk mengekspresikan kreativitasmu**, **memecahkan masalah**, dan **menciptakan pengalaman yang positif bagi pengguna**. Dalam dunia yang semakin berfokus pada **pengalaman pengguna**, **keterampilan di bidang UI/UX sangat berharga dan dibutuhkan**. Jadi, **jangan ragu untuk mengeksplorasi dunia desain ini**. Siapa tahu, mungkin ini adalah **langkah awal untuk karir yang memuaskan dan penuh tantangan di dunia teknologi! 🚀✨**`,
      `\n**Mengembangkan Keterampilan UI/UX**\n
      Dalam rangka mempersiapkan diri untuk memasuki dunia UI/UX, kamu bisa mulai dengan **mempelajari alat desain** seperti **Figma, Sketch, atau Adobe XD**. Alat-alat ini sangat populer di kalangan desainer karena **kemudahan penggunaan dan kemampuan kolaborasi**. Selain itu, jangan lupakan untuk terus **mengikuti tren terbaru dalam desain**, baik melalui **kursus online, webinar, atau komunitas desain**. **Bergabung dengan komunitas** dapat memberimu **wawasan berharga** dan **memperluas jaringanmu di industri ini**.`,
      `\n**Pentingnya Pengalaman Praktis**\n
      **Pengalaman praktis** sangat penting dalam mengembangkan keterampilan UI/UX. **Mengerjakan proyek nyata**, baik itu proyek pribadi atau proyek freelance, membantu kamu untuk **menerapkan teori dalam praktik**. Selain itu, **magang di perusahaan desain** atau **berkontribusi dalam proyek open source** bisa memberikan **pengalaman berharga** dan **menambah portofolio**.`,
      `\n**Kesimpulan**\n
      **UI/UX bukan hanya tentang membuat sesuatu yang terlihat bagus**, tetapi juga tentang **menciptakan pengalaman yang berarti dan fungsional**. Jadi, **siapkan dirimu untuk menjelajahi dunia yang penuh kreativitas ini!** Dengan **dedikasi dan semangat belajar**, kamu bisa **mengembangkan keterampilan UI/UX** yang **membuka banyak peluang karir** di dunia teknologi. **Selamat mencoba!**`
    ]
  },
  {
    id: 5,
    title: 'Tips Membuat Desain Responsif: Biar Website Kamu Tampil Kece di Segala Ukuran Layar!',
    author: 'Septian Hidayat',
    date: '12 September, 2024',
    image: responsif,
    credit:'pexels.com',
    content:  `
    Di dunia digital saat ini, membuat desain yang responsif bukan hanya sekadar pilihan tetapi sudah menjadi kebutuhan. Dengan beragam ukuran layar yang digunakan oleh pengguna, mulai dari smartphone hingga monitor besar, penting banget untuk memastikan bahwa website kamu tetap terlihat menarik dan fungsional di semua perangkat. Nah, di sini aku bakal kasih beberapa tips seru untuk membantu kamu menciptakan desain responsif yang kece!
  
    Pertama-tama, pahami konsep grid sistem. Desain responsif seringkali menggunakan grid yang fleksibel, di mana elemen-elemen dalam layout akan menyesuaikan diri dengan lebar layar. Dengan menggunakan unit pengukuran seperti persentase, bukannya pixel, kamu bisa memastikan bahwa elemen-elemen pada halaman tetap seimbang dan proporsional tanpa terganggu oleh perubahan ukuran layar. Cobalah gunakan CSS Grid atau Flexbox untuk mempermudah proses ini.
  
    Selanjutnya, penting banget untuk memilih gambar yang responsif. Gambar adalah salah satu elemen yang dapat mempengaruhi performa dan tampilan website. Gunakan teknik seperti srcset di HTML untuk menyajikan gambar yang berbeda sesuai dengan ukuran layar yang digunakan. Ini akan mengurangi waktu muat halaman dan memastikan gambar tetap tajam di semua perangkat.
  
    Jangan lupa untuk mengutamakan tipografi yang dapat disesuaikan. Pilih font yang mudah dibaca di layar kecil dan pastikan ukuran font dapat diubah sesuai dengan ukuran layar. Gunakan unit em atau rem untuk membuat ukuran font lebih fleksibel. Selain itu, jangan ragu untuk mengatur line height dan spacing agar teks terlihat lebih nyaman dibaca, apalagi saat diakses melalui perangkat mobile.
  
    Sekarang, mari kita bicara tentang media queries. Ini adalah senjata pamungkas dalam menciptakan desain responsif! Dengan menggunakan media queries, kamu dapat menetapkan aturan CSS yang berbeda untuk perangkat dengan ukuran layar tertentu. Misalnya, kamu bisa mengubah ukuran font, menyembunyikan elemen tertentu, atau bahkan mengubah layout sepenuhnya hanya dengan menambahkan beberapa baris kode. Cobalah untuk bereksperimen dengan berbagai breakpoint untuk menemukan apa yang terbaik untuk desain kamu.
  
    Selain itu, jangan lupakan navigasi yang ramah pengguna. Navigasi yang sederhana dan intuitif sangat penting, terutama di perangkat mobile. Pertimbangkan untuk menggunakan menu hamburger atau dropdown untuk menyimpan ruang di layar kecil, namun tetap pastikan pengguna bisa dengan mudah menemukan apa yang mereka butuhkan.
  
    Agar lebih jelas, mari kita lihat studi kasus sebuah situs web yang sukses menerapkan desain responsif. Sebuah platform media sosial yang populer memutuskan untuk merombak desain mereka untuk meningkatkan pengalaman pengguna di perangkat mobile. Dengan menerapkan grid fleksibel, gambar responsif, dan menu navigasi yang sederhana, mereka berhasil meningkatkan interaksi pengguna hingga 30 persen. Ini menunjukkan betapa besar dampak desain responsif terhadap keterlibatan pengguna.
  
    Terakhir, jangan lupa untuk menguji desain kamu di berbagai perangkat dan resolusi. Tools seperti Chrome DevTools atau layanan online lainnya memungkinkan kamu untuk melihat bagaimana tampilan website kamu di berbagai ukuran layar. Ini penting agar kamu bisa melihat apakah semuanya bekerja dengan baik sebelum meluncurkan website kamu ke publik.
  
    Dengan menerapkan tips-tips ini, kamu bisa menciptakan desain responsif yang tidak hanya terlihat keren tetapi juga memberikan pengalaman pengguna yang luar biasa. Ingat, di dunia yang semakin mobile ini, desain responsif adalah kunci untuk mencapai kesuksesan. Jadi, siap-siap untuk menjelajahi dunia desain yang lebih fleksibel dan dinamis!
    `  },
];
