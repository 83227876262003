import styled from 'styled-components';

// Container for the entire education section
export const Container = styled.div`
  background-color: #;
  padding: 40px;
  justify-content: center;
`;

// Timeline that holds the items and has the vertical line in the center
export const Timeline = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%; /* Vertical line in the center */
    transform: translateX(-50%);
    width: 2px; /* Width of the vertical line */
    height: 100%;
    background-color: #FD3A4A; /* Color of the line */
  }

  /* Media query for mobile devices */
  @media (max-width: 800px) {
    align-items: center; /* Keep center alignment */
    
    /* Set timeline item div to full width */
    div {
      width: 100%; /* Ensure div takes full width */
      text-align: center; /* Center text for mobile */
    }
  }
`;

// Timeline item to align left or right
export const TimelineItem = styled.div<{ align: 'left' | 'right' }>`
  display: flex;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-start' : 'flex-end')};
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  div {
    width: 40%;
    text-align: ${({ align }) => (align === 'left' ? 'left' : 'right')};
  }
`;

// Dot as an image
export const DotImage = styled.img`
  width: 12px; /* Set the width of the dot */
  height: 12px; /* Set the height of the dot */
  position: absolute;
  left: 50%; /* Center the dot horizontally */
  transform: translateX(-50%); /* Correct centering */
  top: 0;
  display: block;
`;
// Title of the timeline item
export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 8px;
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if text is too long */

  /* Mobile font size */
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;
export const Title2 = styled.h3`
  font-size: 18px;
  margin-bottom: 8px;
  white-space: nowrap; /* Prevent line breaks */
  

  /* Mobile font size */
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

// Subtitle for additional context (school or job role)
export const Subtitle = styled.h4`
  color: #8892b0;
  font-size: 16px;
  margin-bottom: 4px;
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if text is too long */

  /* Mobile font size */
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;

// Additional info such as grades or time period
export const Info = styled.p`
  color: #FD3A4A;
  font-size: 14px;
  margin-bottom: 4px;
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if text is too long */

  /* Mobile font size */
  @media (max-width: 800px) {
    font-size: 9px;
  }
`;

// Text for extra information such as job responsibilities or course descriptions
export const Text = styled.p`
  color: #8892b0;
  font-size: 14px;
  margin-bottom: 4px;

  /* Mobile font size */
  @media (max-width: 800px) {
    font-size: 9px;
  }
`;
