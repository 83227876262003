// src/components/Blog/styles.ts
import styled from 'styled-components';

export const BlogContainer = styled.div`
  width: 100vw; /* Memastikan container memenuhi seluruh lebar viewport */
  margin: 0; /* Pastikan tidak ada margin */
  padding: 0; /* Pastikan tidak ada padding */
  overflow-x: hidden; /* Pastikan tidak ada scroll horizontal */
`;
export const BlogCard = styled.div`
  display: flex;
  flex-direction: column; /* Atur ke tata letak kolom */
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: #fff;
  margin: 0;
  padding: 20px 0;
  cursor: pointer;
  height: auto;

  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    margin-top: 20px;
  }

  .text-content {
    text-align: center;
    background-color: #fff;
    padding: 20px;

    p {
      width: 100%;
    }

    .content {
      text-align: justify;
      width: 930px; /* Default width */
    }
  }

  h3 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 10px;
  }

  .blog-info {
    font-size: 1rem;
    color: #777;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    color: #555;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    color: #FD3A4A;
    text-align: center;
    display: block;
    margin: 0 auto;
    padding: 10px 0;
  }

  @media (max-width: 768px) {
    flex-direction: column; /* Pastikan kolom untuk perangkat kecil */
    
    .text-content {
      text-align: center;
      background-color: #fff;
      padding: 20px;
    }

    p {
      width: 100%;
    }

    .content {
      text-align: justify;
      width: 100%; /* Agar responsif */
      max-width: 330px; /* Batas maksimum lebar */
    }
  }
`;


export const SearchBar = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  position: relative; /* Untuk positioning ikon search */

  input {
    width: 50%;
    padding: 10px 40px 10px 15px; /* Tambahkan padding kanan untuk ruang ikon */
    border: 2px solid #FD3A4A;
    color: #FD3A4A;
    border-radius: 25px;
    font-size: 2rem;
    outline: none;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #e63946;
    }

    @media (max-width: 768px) {
    
      width: 80%;
      
    }
    

  
    background: none;
  }

  .search-icon {
    position: absolute;
    right: 27%; /* Sesuaikan dengan lebar input */
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none; /* Agar klik tetap pada input */
  }

  @media (max-width: 768px) {
    .search-icon {
      right: 15%;

    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    cursor: pointer;
    background: none; 
    border: none;     
    color: #FD3A4A;   
    margin: 0 5px;
    font-size: 1.6rem;  
    transition: color 0.3s ease;

    &:hover {
      color: #e63946;  /* Warna teks saat dihover */
    }

    &:disabled {
      cursor: not-allowed;
      color: #ccc;    /* Warna teks yang lebih redup saat tombol dinonaktifkan */
    }
  }
`;

export const AllBlogsContainer = styled.div`
  margin-top: 10rem;
  display: flex;
  flex-direction: row;
  

  @media (max-width: 768px) {
    flex-direction: column;
    .blog-post {
      max-width:100%;
    }
  }
`;

export const BlogList = styled.div`
  flex: 3;
  

  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #FD3A4A;
  }

  .blog-post {
    margin: 20px 0;
    padding: 20px;
    margin-right:5px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(0, 0, 2, 0.1);
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }

    img {
      width: 1px;
      max-height: 350px;
      border-radius: 10px;
    }

    h2 {
      margin: 10px 0;
      color: #FD3A4A;
    }

    p {
      font-size: 10pt;
      text-align: justify;
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
    }
  }
`;

export const Sidebar = styled.div`
  flex: 1;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 6.2rem;
  max-height: 500px; 
  overflow-y: auto;
  position: sticky;
  top: 20px;
  p{
  font-size:1.2rem;
  }
  h2 {
    margin-bottom: 15px;
    font-size:1.5rem;
  }

  .sidebar-item {
    margin-bottom: 10px;
    cursor: pointer;
     font-size:1.2rem;

    &:hover {
      text-decoration: underline;
    }
  }

  /* Menambahkan gaya untuk link sidebar dengan efek hover */
  a {
    color: #FD3A4A; /* Warna link */
    text-decoration: none; /* Tanpa garis bawah */
    display: block; /* Membuat link menjadi blok */
    transition: transform 0.3s ease, color 0.3s ease; /* Transisi untuk efek hover */
 font-size:1.2rem;
    &:hover {
      transform: translateY(-3px); /* Menggeser link sedikit ke atas */
      color: #e63946; /* Warna sedikit lebih gelap saat hover */
    }
  }

  @media (max-width: 768px) {
    position: static; /* Ubah menjadi static agar sidebar berada di atas dalam tampilan mobile */
    margin-bottom: 20px; /* Tambahkan margin bawah agar ada jarak dari konten di bawah */
    max-height: none; /* Hapus batas tinggi maksimum untuk tampilan mobile */
  }
`;
