import styled from "styled-components";

export const Container = styled.section`
  margin-top: 12rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  .hard-skills {
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.8rem;
  }

  .hability {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      cursor: pointer;
    }

    img {
      width: 3.4rem;
      transition: transform 0.3s ease, filter 0.3s ease;

      &:hover {
        transform: translateY(-10px); /* Animasi ke atas */
      }
    }
  }

  h2 {
    display: inline-block;
    font-size: 3rem;
    margin-top: 0rem;
    color: var(--green);
  }

  h3 {
    margin-top: 2rem;
    color: var(--green);
  }

  p {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    font-weight: 200;
    font-family: 'Poppins', sans-serif; /* Perbaikan: menghapus koma terakhir */
  }

  .about-image {
    text-align: center;
    img {
      margin-top: 2rem;
      max-width: 300px;
      filter: grayscale(0);
      transition: filter 0.5s;
      &:hover {
        filter: grayscale(0);
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .about-image {
      max-width: 100%;
      margin-top: 4rem;
      img {
        margin-top: 2rem;
        width: 100%;
        filter: grayscale(0);
        transition: filter 0.5s;
        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }

  @media (max-width: 960px) {
    display: block;
    text-align: justify;

    h2 {
      text-align: center;
    }

    p {
      font-size: 1.3rem;
    }

    .hard-skills {
      justify-content: center;
    }

    .about-image {
      display: flex;
      max-width: 100%;
      img {
        margin-top: 2rem;
        width: 100%;
        filter: grayscale(0);
        transition: filter 0.5s;
        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
`;
