import { useState } from "react";
import { Container } from "./styles";
import certificate1 from "../../assets/certificate1.svg";
import certificate2 from "../../assets/certificate2.svg";
import certificate3 from "../../assets/certificate3.svg";
import certificate4 from "../../assets/certificate4.svg";
import certificate5 from "../../assets/certificate5.svg";
import certificate6 from "../../assets/certificate6.svg";
import ScrollAnimation from "react-animate-on-scroll";
import upIcon from "../../assets/up.png";   // Ganti nama variabel untuk lebih jelas
import downIcon from "../../assets/down.png"; // Ganti nama variabel untuk lebih jelas

export function Certificate() {
  const [showMore, setShowMore] = useState(false);
  const certificates = [
    certificate1,
    certificate2,
    certificate3,
    certificate4,
    certificate5,
    certificate6,
  ];

  // Logika untuk menentukan jumlah sertifikat yang ditampilkan
  const displayedCertificates = showMore
    ? certificates
    : window.innerWidth <= 768
    ? certificates.slice(0, 1)
    : certificates.slice(0, 3);

  return (
    <Container id="certificate" showMore={showMore}>
      <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
        <h2 className="section-title">Certificates</h2>
      </ScrollAnimation>
      
      {/* Tombol untuk toggle antara show more dan show less */}
      <button className="toggle-button" onClick={() => setShowMore(!showMore)}>
        <img style={{width:"1.9rem"}} src={showMore ? upIcon : downIcon} alt="Toggle Icon" />
      </button>

      <ScrollAnimation animateIn="fadeInUp" delay={0.5 * 1000}>
        <div className="certificate-gallery">
          {displayedCertificates.map((certificate, index) => (
            <div key={index} className={`certificate-item ${showMore ? "fade-in" : ""}`}>
              <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
                <img src={certificate} alt={`Certificate ${index + 1}`} />
              </ScrollAnimation>
            </div>
          ))}
        </div>
      </ScrollAnimation>
    </Container>
    
  );
}
