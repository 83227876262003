import React, { useState, useEffect } from 'react';
import { Container, Timeline, TimelineItem, DotImage, Title,Title2, Subtitle, Info, Text } from './styles';
import circle from "../../assets/circle.png"; // Import the image
import ScrollAnimation from 'react-animate-on-scroll';

export function Education() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
        <Timeline>
          <TimelineItem align="right">
            <DotImage src={circle} alt="dot" />
            <div>
              <Title2 style={{marginLeft:"-1rem"}}>{isMobile ? 'Indraprasta PGRI ' : 'Universitas Indraprasta PGRI'}</Title2>
              <Subtitle>{isMobile ? 'Informatika' : 'Informatics Engineering'}</Subtitle>
              <Info>Grade: <span style={{ fontStyle: "italic" }}>In Progress</span></Info>
              <Text>September 2024 - Present</Text>
            </div>
          </TimelineItem>
          <TimelineItem align="left">
            <DotImage src={circle} alt="dot" />
            <div>
              <Title2>{isMobile ? 'SMKN 1 Sumedang' : 'SMK Negeri 1 Sumedang'}</Title2>
              <Subtitle>{isMobile ? 'RPL' : 'Software Engineering'}</Subtitle>
              <Text> <span> </span>July 2021 - May 2024 </Text>
              <Text>Internship at Sumedang Prosecutor's Office</Text>
            </div>
          </TimelineItem>
          <TimelineItem align="right">
            <DotImage src={circle} alt="dot" />
            <div>
              <Title>Freelance | Junior Web and Desktop app Developer</Title>
              <Subtitle>Remote</Subtitle>
              <Text>August 2024 - Present</Text>
              <Text>Programming Languages: HTML, JavaScript, PHP, C#, Java</Text>
              <Text>{isMobile ? ' ' : 'Frameworks: Laravel, React.js, TailwindCSS, CodeIgniter, Bootstrap '}</Text>
            </div>
          </TimelineItem>
        </Timeline>
      </ScrollAnimation>
    </Container>
  );
}

export default Education;
