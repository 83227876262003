import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Blog } from './components/Blog/Blog';
import { AllBlogs } from './components/Blog/DetailBlog';
import { Intro } from './components/Intro/Intro';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { Contact } from './components/Contact/Contact';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Main } from './components/Main/Main';
import { GlobalStyle } from './styles/global';
import ScrollToTop from './components/ScrollToTop';

function App() {
  // Check localStorage to determine if intro should be shown
  const [showIntro, setShowIntro] = useState(() => {
    const introStatus = localStorage.getItem('introShown');
    return introStatus !== 'true'; // If 'introShown' is not true, show intro
  });

  // Function to handle when intro is complete
  const handleIntroComplete = () => {
    setShowIntro(false); // Hide intro
    localStorage.setItem('introShown', 'true'); // Save status in localStorage
  };

  return (
    <>
      <GlobalStyle />
      <Router>
        <ScrollToTop />
        {showIntro ? (
          <Intro onComplete={handleIntroComplete} /> // Pass function to Intro
        ) : (
          <>
            <Header />
            <Routes>
              <Route 
                path="/" 
                element={
                  <>
                    <Main />
                    <br /><br />
                    <ScrollAnimation animateIn="fadeInUp" delay={0.30 * 2000}><hr /></ScrollAnimation>
                    <br />
                    <ScrollAnimation animateIn="fadeInUp">
                      <h1 style={{ textAlign: "center" }}>Blogs</h1>
                    </ScrollAnimation>
                    <br />
                    <Blog />
                    <br />
                    <ScrollAnimation animateIn="fadeInUp" delay={0.30 * 1000}><hr /></ScrollAnimation>
                    <Contact />
                  </>
                } 
              />
              <Route path="/blogs" element={<AllBlogs />} />
            </Routes>
            <Footer />
          </>
        )}
      </Router>
    </>
  );
}

export default App;
