// src/components/Blog/Blog.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { blogsData } from './blogData';
import { BlogContainer, BlogCard } from './styles'; // Import styles
import ScrollAnimation from "react-animate-on-scroll";
import more from "../../assets/more.png";
const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};
export function Blog() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    navigate('/blogs');
  };

  return (
    <BlogContainer>
      {blogsData.slice(0, 1).map((blog, index) => (
        <ScrollAnimation animateIn="fadeInUp" delay={index * 200} key={blog.id}>
          <BlogCard onClick={handleClick}>
            <div className="text-content">
              <h3>{blog.title}</h3>
              <p className="blog-info">
                <strong>{blog.date}</strong> by {blog.author}
              </p>
              <p className='content' style={{}}>
                {truncateText(
                  Array.isArray(blog.content) ? blog.content.join(' ') : blog.content,
                  200
                )}
              </p>
            </div>
            <img src={blog.image} alt={blog.title} />
            <h5 style={{marginTop:"5px",color:"gray"}}>Image by : {blog.credit}</h5>
            <button>Read More <img src={more} alt="Read More" style={{marginLeft:"2px",width:"10px"}} /></button>
          </BlogCard>
        </ScrollAnimation>
      ))}
    </BlogContainer>
  );
}