// src/components/AllBlogs.tsx
import React, { useState, useEffect } from 'react';
import { blogsData } from './blogData';
import { AllBlogsContainer, BlogList, Sidebar, PaginationContainer, SearchBar } from './styles';
import ScrollAnimation from "react-animate-on-scroll";
import ian from "../../assets/iann.png";
import li from "../../assets/linkedin.svg";
import git from "../../assets/github.svg";
import ig from "../../assets/instagram.svg";
import more from "../../assets/more.png";
import searchIcon from "../../assets/search.png"; // Import ikon search

export function AllBlogs() {
  const [expandedBlogId, setExpandedBlogId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [targetBlogId, setTargetBlogId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(''); // State untuk search term
  const [filteredBlogs, setFilteredBlogs] = useState<typeof blogsData>(blogsData); // State untuk blog yang difilter
  const blogsPerPage = 3;

  // Update filteredBlogs setiap kali searchTerm berubah
  useEffect(() => {
    const term = searchTerm.toLowerCase();
    const filtered = blogsData.filter(blog => blog.title.toLowerCase().includes(term));
    setFilteredBlogs(filtered);
    setCurrentPage(1); // Reset ke halaman pertama setelah pencarian
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll ke atas setelah pencarian
  }, [searchTerm]);

  // Fungsi untuk toggle expand/collapse blog content
  const toggleExpand = (id: number) => {
    setExpandedBlogId(expandedBlogId === id ? null : id);
  };

  // Fungsi untuk menentukan halaman target dan mengatur currentPage serta targetBlogId
  const handleScrollToBlog = (id: number) => {
    const blogIndex = filteredBlogs.findIndex(blog => blog.id === id);
    if (blogIndex !== -1) {
      const targetPage = Math.floor(blogIndex / blogsPerPage) + 1;
      setCurrentPage(targetPage);
      setTargetBlogId(id);
    }
  };

  // Fungsi untuk mengubah teks di antara ** menjadi bold
  const convertToBoldJSX = (text: string): React.ReactNode[] => {
    const parts = text.split(/(\*\*.*?\*\*)/g);

    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  // Fungsi untuk menangani \n dan bold dalam teks
  const convertToBoldAndLineBreaks = (text: string): React.ReactNode[] => {
    const lines = text.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {convertToBoldJSX(line)}
        <br />
      </React.Fragment>
    ));
  };

  // Menghitung indeks blog yang akan ditampilkan
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);
  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  // Fungsi untuk menangani klik pada tombol "Halaman Berikutnya"
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll ke atas saat berpindah halaman
    }
  };

  // Fungsi untuk menangani klik pada tombol "Halaman Sebelumnya"
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll ke atas saat berpindah halaman
    }
  };

  // useEffect untuk melakukan scroll setelah currentPage diperbarui
  useEffect(() => {
    if (targetBlogId !== null) {
      const blogElement = document.getElementById(`blog-${targetBlogId}`);
      if (blogElement) {
        blogElement.scrollIntoView({ behavior: 'smooth' });
        // Reset targetBlogId setelah scrolling
        setTargetBlogId(null);
      }
    }
  }, [currentPage, targetBlogId]);

  return (
    <AllBlogsContainer>
      <BlogList>
   
        <h1>My Blogs</h1>
     
        <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 2000}>
        <SearchBar>
          <input
            type="text"
            placeholder="Search blogs by title..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-label="Search Blogs"
          />
          <img src={searchIcon} alt="Search Icon" className="search-icon" />
        </SearchBar>
        </ScrollAnimation>
        {currentBlogs.length > 0 ? (
          currentBlogs.map((blog) => {
            const fullContent = Array.isArray(blog.content) ? blog.content.join('\n') : blog.content || '';
            const fullContent2 = Array.isArray(blog.content2) ? blog.content2.join('\n') : blog.content2 || '';
            const isExpanded = expandedBlogId === blog.id;

            const contentToShow = isExpanded 
              ? fullContent + '\n' + fullContent2 
              : fullContent.substring(0, 100) + '...' + (fullContent2 ? '\n' + fullContent2.substring(0, 100) + '...' : '');

            return (
              <div key={blog.id} id={`blog-${blog.id}`} className="blog-post">
                <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 2000}>
                  <h2>{blog.title}</h2>
                  <p style={{ fontSize: '10pt' }}><strong>{blog.date}</strong> by {blog.author}</p>
                  <br />
                  <img src={blog.image} alt={blog.title} style={{ width: '100%', height: 'auto' }} />
                  <h5 style={{ marginTop: "3px", color: "gray", marginBottom: "20px", fontSize: "8pt", textAlign: "center" }}>Image by : {blog.credit}</h5>
                  <div style={{ fontFamily: "Poppins ,sans-serif", fontWeight: "300", fontSize:"10pt" }}>
                    {convertToBoldAndLineBreaks(contentToShow)}
                  </div>
                  <button 
                    onClick={() => toggleExpand(blog.id)} 
                    style={{ 
                      cursor: 'pointer', 
                      border: 'none',  
                      background: 'none',  
                      color: '#FD3A4A',  
                      textAlign: 'center',  
                      display: 'block',  
                      margin: '0 auto',   
                      padding: '10px 0',  
                    }}
                  >
                    {isExpanded ? 'Show Less' : 'Read More'}
                    <img src={more} alt="Read More" style={{marginLeft:"2px",width:"10px"}} />
                  </button>
                </ScrollAnimation>
              </div>
            );
          })
        ) : (
          <p style={{ textAlign: 'center', color: '#FD3A4A' }}>No blogs found matching your search.</p>
        )}

        {/* Tombol Navigasi Paginasi */}
        {filteredBlogs.length > blogsPerPage && (
          <PaginationContainer>
            {currentPage > 1 && (
              <button 
                onClick={handlePrevPage}
              >
                ← Previous Pages
              </button>
            )}
            {currentPage < totalPages && (
              <button 
                onClick={handleNextPage}
              >
                Next Pages →
              </button>
            )}
          </PaginationContainer>
        )}
      </BlogList>
      
      <Sidebar>
        <img src={ian} alt="Septian Hidayat Hidayat" style={{ borderRadius: '50%', width: '100px', height: '100px', marginBottom: '15px' }} />
        <h2>About Me</h2>
        <p>Hi! I'm a Junior Front-End Developer and Graphic Designer. I love creating beautiful and functional websites.</p>
        <br /><hr /><br />
        <h2>Recent Posts</h2>
        <ul>
          {blogsData.slice(0, 5).map((blog) => (
            <li key={blog.id} style={{ marginBottom: '10px' }}>
              {/* Tambahkan onClick untuk menangani scroll */}
              <a 
                href="#" 
                onClick={(e) => { 
                  e.preventDefault(); 
                  handleScrollToBlog(blog.id); 
                }} 
                style={{ color: '#FD3A4A', textDecoration: 'none' }}
              >
                {blog.title}
              </a>
            </li>
          ))}
        </ul>
        <br /><hr /><br />
        <h2>Categories</h2>
        <div className="sidebar-item">Web Development</div>
        <div className="sidebar-item">Graphic Design</div>
        <div className="sidebar-item">UI/UX Design</div>
        <br /><hr /><br />
        <h2>Follow Me</h2>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
          <a href="https://www.linkedin.com/in/septianhdyt" target="_blank" rel="noopener noreferrer">
            <img src={li} alt="LinkedIn" style={{ width: '30px', height: '30px' }} />
          </a>
          <a href="https://github.com/septianhdyt" target="_blank" rel="noopener noreferrer">
            <img src={git} alt="GitHub" style={{ width: '30px', height: '30px' }} />
          </a>
          <a href="https://instagram.com/iannsl9" target="_blank" rel="noopener noreferrer">
            <img src={ig} alt="Instagram" style={{ width: '30px', height: '30px' }} />
          </a>
        </div>
      </Sidebar>
    </AllBlogsContainer>
  );
}
