// Design.tsx

import {
  Container,
  GalleryGrid,
  ImageWrapper,
  FullscreenOverlay,
  NavButton,
  PositionIndicator,
} from "./styles";
import { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { motion, AnimatePresence } from "framer-motion";
import d1 from "../../assets/d1-min.png";
import d2 from "../../assets/d2-min.png";
import d3 from "../../assets/d3-min.png";
import d4 from "../../assets/d4-min.png";
import d5 from "../../assets/d5-min.png";
import d6 from "../../assets/d6-min.png";
import d7 from "../../assets/d7-min.png";
import d8 from "../../assets/d8-min.png";
import d9 from "../../assets/d9-min.png";

export function Design() {
  const designs = [
    { src: d1, title: "Bali", description: "A poster design of Pura Ulun Danu" },
    {
      src: d2,
      title: "Dominik Szoboslai",
      description: "A poster design of Liverpool's player",
    },
    { src: d3, title: "Burger", description: "A poster design of food ads" },
    {
      src: d4,
      title: "AyoRental",
      description: "A poster design of Car Rent ads",
    },
    {
      src: d5,
      title: "Play Offs Basket",
      description: "A poster design for show information",
    },
    { src: d6, title: "Shoes", description: "A poster design of shoes ads" },
    {
      src: d7,
      title: "Mo Salah",
      description: "A poster design of Liverpool's player",
    },
    { src: d8, title: "Drink", description: "A poster design of soda drink ads" },
    { src: d9, title: "Burger2", description: "A poster design of food ads" },
  ];

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  // Fungsi untuk menampilkan gambar sebelumnya
  const handlePrev = (e: React.MouseEvent) => {
    e.stopPropagation(); // Mencegah event bubbling ke FullscreenOverlay
    if (selectedIndex !== null && !isAnimating) {
      setIsAnimating(true);
      setSelectedIndex((selectedIndex - 1 + designs.length) % designs.length);
    }
  };

  // Fungsi untuk menampilkan gambar berikutnya
  const handleNext = (e: React.MouseEvent) => {
    e.stopPropagation(); // Mencegah event bubbling ke FullscreenOverlay
    if (selectedIndex !== null && !isAnimating) {
      setIsAnimating(true);
      setSelectedIndex((selectedIndex + 1) % designs.length);
    }
  };

  // Menambahkan navigasi keyboard
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (selectedIndex !== null && !isAnimating) {
        if (e.key === "ArrowLeft") {
          setIsAnimating(true);
          setSelectedIndex(
            (selectedIndex - 1 + designs.length) % designs.length
          );
        } else if (e.key === "ArrowRight") {
          setIsAnimating(true);
          setSelectedIndex((selectedIndex + 1) % designs.length);
        } else if (e.key === "Escape") {
          setSelectedIndex(null);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener saat komponen di-unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedIndex, designs.length, isAnimating]);

  return (
    <Container id="design">
      <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
        <h1 style={{ textAlign: "center", fontSize: "3rem" }}>
          My Design Gallery
        </h1>
      </ScrollAnimation>
      <br />
      <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
        <h6 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Click to see full image
        </h6>
      </ScrollAnimation>

      <GalleryGrid>
        {designs.map((design, index) => (
          <ScrollAnimation
            key={index}
            animateIn="fadeInUp"
            delay={0.2 * index * 1000} // Animasi berurutan dengan delay berdasarkan index
          >
            <ImageWrapper onClick={() => setSelectedIndex(index)}>
              <img src={design.src} alt={design.title} />
              <div className="description-overlay">
                <h3>{design.title}</h3>
                <p>{design.description}</p>
              </div>
            </ImageWrapper>
          </ScrollAnimation>
        ))}
      </GalleryGrid>

      <AnimatePresence>
        {selectedIndex !== null && (
          <FullscreenOverlay
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedIndex(null)}
            aria-modal="true"
            role="dialog"
          >
            <NavButton
              left
              onClick={handlePrev}
              aria-label="Previous Image"
            >
              &#8249;
            </NavButton>
            <motion.img
              key={selectedIndex} // Menambahkan key untuk memicu re-render
              src={designs[selectedIndex].src}
              alt={designs[selectedIndex].title}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.5 }}
              onAnimationComplete={() => setIsAnimating(false)}
              onClick={(e) => e.stopPropagation()} // Mencegah klik di dalam gambar menutup overlay
            />
            <NavButton onClick={handleNext} aria-label="Next Image">
              &#8250;
            </NavButton>
            <PositionIndicator>
              {selectedIndex + 1} of {designs.length}
            </PositionIndicator>
          </FullscreenOverlay>
        )}
      </AnimatePresence>
    </Container>
  );
}
